
.product-container{
    display: flex;
    flex-direction: column;
    border: solid 1px #7e7e7e;
    border-radius: 5px;
    padding: 16px;
    height: 250px;
    width:auto;
}

.name{
    margin: 0;
    font-size: 14px;
    font-family: NotoSansDisplay, NotoSansDisplay-fallback, Arial, sans-serif;
    font-weight: 700;
}

.description{
    display: flex;
    height: 100%;
    font-size: 14px;
    font-family: NotoSansDisplay, NotoSansDisplay-fallback, Arial, sans-serif;
    font-weight: 700;
}

.product-image{
    height: 80%;
    width: fit-content;
}

.price-contaner{
    text-decoration: line-through;
}
