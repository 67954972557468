
.top-banner{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    background-color: rgb(189, 188, 253);
}

.banner-component{
    margin: 0;
    align-items: center;
    font-size: 0.7rem;
}

.banner-spec{
    font-weight: bold;
}

.ship-logo{
    padding-right: 5px;
    align-items: center;
}

.main-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-bottom: solid 1px #ccc;
    padding: 0 0.25rem;
    box-sizing: border-box;
    position: sticky;
    top: 0;
    background-color: white;
    box-shadow: 0px 2px 5px 0px rgb(221, 221, 221);
}

.brand-logo{
    display: flex;
}

.logo{
    margin: 0.25rem;
    width: 400px;
}

.control-panel{
    display: flex;
    align-items: center;
}

@media (max-width: 839px) { 
    .logo{
        width: 350px;
    }
}

@media (max-width: 751px) {

    .main-header{
        flex-wrap: wrap;
    }
    
}

@media (max-width: 481px) {
    .logo{
        width: 220px;
    }
}