
.container{
    display: flex;
    flex-direction: column;
    padding: 16px;
    align-items: center;
}

.logo{
    height: 79px;
}

.box{
    border: 1px #ccc;
}

.user-form{
    display: flex;
    flex-direction: column;
}

input{
    margin: 5px;
}