
.cart-container{
    display: flex;
    align-items: center;
    margin: 0.25rem;
    color: black;
    text-decoration: none;
}

.cart-container:hover{
    background-color: #f2f2f2;
}

.cart-logo{
    padding: 1rem;
    font-size: 1.5rem;
}

@media (max-width: 839px) {

    .cart-status{
        display: none;
    }

    .cart-container{
        border: 1px solid black;
        border-radius: 100%;
        font-size: 1.5rem;
    }

    .cart-logo{
        padding: 0.5rem;
        font-size: 1.5rem;
    }
}