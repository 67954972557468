
.catalogue-container{
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 0.5rem 0;
    box-sizing: content-box;
}

.sort-menu{
    display: flex;
    width: 20%;
    border: solid 1px #7e7e7e;
    border-radius: 5px;
}

.product-list{
    width: 80%;
    background-color: blue;
}