.chat{
    display: block;
    position: fixed;
    z-index: 22;
    bottom: 1px;
    right: 0px;
    transition: all 0.5s ease 0s;
}

@media (min-width: 512px) {
    .chat {
        width: 340px;
        height: auto;
        background-color: transparent;
    }

    .chat-container{
        position: relative;
        bottom: 76px;
        width: auto;
        box-sizing: border-box;
        right: 16px;
    }
}

.chat-container{
    transform: translateY(0px);
    position: relative;
    display: flex;
    background-color: white;
    border: black solid 1px;
    border-radius: 5px;
}

@media (min-width: 752px)
{
    .chat-container{
        bottom: 84px;
    }
}

.chat-launcher{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 16px;
    bottom: 16px;
    z-index: 1;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 32px;
    background: rgb(255, 133, 27);
    border: none;
    color: rgb(0, 13, 217);
    cursor: pointer;
}

.assistant-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}

.close-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}