
.account-container{
    display: flex;
    align-items: center;
    margin: 0.25rem;
    text-decoration: none;
    color: black;
}

.account-container:hover{
    background-color: #f2f2f2;
}

.user-logo{
    font-size: 1.75rem;
    padding: 1rem;
}

@media (max-width: 839px){

    .account-status{
        display: none;
    }

    .account-container{
        border: 1px solid black;
        border-radius: 100%;
        height: 40px;
        width: 40px;
    }

    .user-logo{
        padding: 0.5rem;
        font-size: 1.5rem;
    }
}
