
.search-bar{
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    width: 45%;
    box-sizing: border-box;
    height: 48px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 0.25rem;
    margin: 0 0 0 1rem;
}

@media (max-width: 751px) {
    .search-bar{
        order: 4;
        width: 100%;
        margin: 4px;
        flex-basis: 100%;
    }
}

@media (max-width: 481px) {
    
}

.search-bar:hover{
    border-color: orange;
}

.search-from{

}

.search-input{
    width: 100%;
   border: 0px;
   box-sizing: content-box;
   display: block;
   font-size: 16px;
   padding-left: 16px;
}

.search-input::-webkit-search-cancel-button{
    display: none;
}

.search-input:focus-visible{
    outline: none;
}

.search-submit{
    border: none;
    background-color: transparent;
    padding-left: 0;
}

.search-logo-container{
    display: flex;
    padding: 8px;
    padding-left: 4px;
}

.search-logo{
    font-size: 20px;
}

.search-logo:hover{
    font-size: 22px;
    transition: font-size 0.3s ease 0s;
}

